import FaeOrderQuiz from './FaeOrderQuiz';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
function App() {
  return (
    <div className="App">
      <CssBaseline />
	  <Container maxWidth="lg">
	  <h1>Discover Your Fae Order</h1>
	  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
      <FaeOrderQuiz /></Box>
	  </Container>
    </div>
  );
}

export default App;