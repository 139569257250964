import React, { useState } from 'react';
import Card from '@mui/material/Card';
//import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@mui/material/card";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
//import { Button } from "@/components/ui/button";
import Button from '@mui/material/Button';
//import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

//import { Label } from "@/components/ui/label";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
//import { ScrollArea } from "@/components/ui/scroll-area";
//import { Progress } from "@/components/ui/progress";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import ordersdb from './ordersdb';

// Sanitize function to prevent XSS
const sanitizeText = (text) => {
  const div = document.createElement('div');
  div.textContent = text;
  return div.innerHTML;
};

const questions = [
  {
    question: "How do you typically approach problem-solving?",
    answers: [
      { text: "Analyze methodically", traits: { analytical: 2, knowledge: 1 } },
      { text: "Trust your instincts", traits: { instinctive: 2, adaptable: 1 } },
      { text: "Seek advice from others", traits: { social: 2, empathetic: 1 } },
      { text: "Take immediate action", traits: { courageous: 2, physical: 1 } },
    ],
  },
  {
    question: "What's your preferred environment?",
    answers: [
      { text: "A quiet library", traits: { knowledge: 2, analytical: 1 } },
      { text: "The great outdoors", traits: { nature: 2, physical: 1 } },
      { text: "A bustling city", traits: { social: 2, adaptable: 1 } },
      { text: "A high-tech laboratory", traits: { analytical: 2, innovative: 1 } },
    ],
  },
  {
    question: "How do you recharge after a long day?",
    answers: [
      { text: "Reading a book", traits: { knowledge: 2, introspective: 1 } },
      { text: "Going for a run", traits: { physical: 2, energetic: 1 } },
      { text: "Spending time with friends", traits: { social: 2, empathetic: 1 } },
      { text: "Meditating or reflecting", traits: { introspective: 2, magical: 1 } },
    ],
  },
  {
    question: "In a group project, what role do you usually take?",
    answers: [
      { text: "The leader", traits: { leadership: 2, courageous: 1 } },
      { text: "The idea generator", traits: { innovative: 2, adaptable: 1 } },
      { text: "The peacemaker", traits: { empathetic: 2, social: 1 } },
      { text: "The detail-oriented executor", traits: { analytical: 2, perfectionist: 1 } },
    ],
  },
  {
    question: "How do you handle unexpected changes?",
    answers: [
      { text: "Adapt quickly", traits: { adaptable: 2, instinctive: 1 } },
      { text: "Analyze the new situation", traits: { analytical: 2, knowledge: 1 } },
      { text: "Seek support from others", traits: { social: 2, empathetic: 1 } },
      { text: "Rely on past experiences", traits: { instinctive: 2, introspective: 1 } },
    ],
  },
  {
    question: "What's your approach to learning new skills?",
    answers: [
      { text: "Practice repeatedly", traits: { perfectionist: 2, physical: 1 } },
      { text: "Study theory thoroughly", traits: { knowledge: 2, analytical: 1 } },
      { text: "Learn from others' experiences", traits: { social: 2, adaptable: 1 } },
      { text: "Experiment and innovate", traits: { innovative: 2, courageous: 1 } },
    ],
  },
  {
    question: "How do you prefer to communicate?",
    answers: [
      { text: "Direct and straightforward", traits: { courageous: 2, leadership: 1 } },
      { text: "Thoughtful and diplomatic", traits: { empathetic: 2, social: 1 } },
      { text: "Detailed and precise", traits: { analytical: 2, perfectionist: 1 } },
      { text: "Expressive and animated", traits: { energetic: 2, social: 1 } },
    ],
  },
  {
    question: "What's your biggest strength?",
    answers: [
      { text: "Physical prowess", traits: { physical: 2, courageous: 1 } },
      { text: "Emotional intelligence", traits: { empathetic: 2, social: 1 } },
      { text: "Logical thinking", traits: { analytical: 2, knowledge: 1 } },
      { text: "Creativity", traits: { innovative: 2, adaptable: 1 } },
    ],
  },
  {
    question: "How do you handle conflicts?",
    answers: [
      { text: "Face them head-on", traits: { courageous: 2, leadership: 1 } },
      { text: "Mediate and find compromise", traits: { empathetic: 2, social: 1 } },
      { text: "Analyze and solve logically", traits: { analytical: 2, knowledge: 1 } },
      { text: "Adapt and find creative solutions", traits: { adaptable: 2, innovative: 1 } },
    ],
  },
  {
    question: "What motivates you the most?",
    answers: [
      { text: "Acquiring knowledge", traits: { knowledge: 2, analytical: 1 } },
      { text: "Helping others", traits: { empathetic: 2, social: 1 } },
      { text: "Achieving personal goals", traits: { perfectionist: 2, courageous: 1 } },
      { text: "Exploring new experiences", traits: { adaptable: 2, innovative: 1 } },
    ],
  },
  {
    question: "How do you view rules and traditions?",
    answers: [
      { text: "Essential for order", traits: { perfectionist: 2, analytical: 1 } },
      { text: "Guidelines to be flexible with", traits: { adaptable: 2, innovative: 1 } },
      { text: "Important for social harmony", traits: { social: 2, empathetic: 1 } },
      { text: "Challenges to be questioned", traits: { courageous: 2, instinctive: 1 } },
    ],
  },
  {
    question: "What's your ideal way to spend a day off?",
    answers: [
      { text: "Physical activities or sports", traits: { physical: 2, energetic: 1 } },
      { text: "Reading or studying", traits: { knowledge: 2, introspective: 1 } },
      { text: "Socializing with friends", traits: { social: 2, empathetic: 1 } },
      { text: "Exploring nature", traits: { nature: 2, instinctive: 1 } },
    ],
  },
  {
    question: "How do you approach decision-making?",
    answers: [
      { text: "Gather all available information", traits: { analytical: 2, knowledge: 1 } },
      { text: "Go with your gut feeling", traits: { instinctive: 2, courageous: 1 } },
      { text: "Consider the impact on others", traits: { empathetic: 2, social: 1 } },
      { text: "Weigh pros and cons carefully", traits: { perfectionist: 2, introspective: 1 } },
    ],
  },
  {
    question: "What's your attitude towards change?",
    answers: [
      { text: "Embrace it enthusiastically", traits: { adaptable: 2, innovative: 1 } },
      { text: "Approach it cautiously", traits: { analytical: 2, perfectionist: 1 } },
      { text: "See it as an opportunity for growth", traits: { courageous: 2, knowledge: 1 } },
      { text: "Focus on how it affects others", traits: { empathetic: 2, social: 1 } },
    ],
  },
  {
    question: "How do you handle stress?",
    answers: [
      { text: "Exercise or physical activity", traits: { physical: 2, energetic: 1 } },
      { text: "Analyze and plan", traits: { analytical: 2, perfectionist: 1 } },
      { text: "Seek support from others", traits: { social: 2, empathetic: 1 } },
      { text: "Meditate or practice mindfulness", traits: { introspective: 2, magical: 1 } },
    ],
  },
  {
    question: "What's your approach to teamwork?",
    answers: [
      { text: "Take charge and delegate", traits: { leadership: 2, courageous: 1 } },
      { text: "Contribute ideas and adapt", traits: { innovative: 2, adaptable: 1 } },
      { text: "Support and encourage others", traits: { empathetic: 2, social: 1 } },
      { text: "Focus on perfecting your part", traits: { perfectionist: 2, analytical: 1 } },
    ],
  },
  {
    question: "How do you view the world around you?",
    answers: [
      { text: "Full of patterns to analyze", traits: { analytical: 2, knowledge: 1 } },
      { text: "Rich with sensory experiences", traits: { instinctive: 2, nature: 1 } },
      { text: "A network of relationships", traits: { social: 2, empathetic: 1 } },
      { text: "Full of mysteries to uncover", traits: { innovative: 2, magical: 1 } },
    ],
  },
  {
    question: "What's your preferred way of expressing yourself?",
    answers: [
      { text: "Through logical arguments", traits: { analytical: 2, knowledge: 1 } },
      { text: "Through actions and deeds", traits: { physical: 2, courageous: 1 } },
      { text: "Through emotional connections", traits: { empathetic: 2, social: 1 } },
      { text: "Through creative outlets", traits: { innovative: 2, adaptable: 1 } },
    ],
  },
  {
    question: "How do you approach challenges?",
    answers: [
      { text: "Break them down methodically", traits: { analytical: 2, perfectionist: 1 } },
      { text: "Face them with determination", traits: { courageous: 2, physical: 1 } },
      { text: "Seek collaborative solutions", traits: { social: 2, empathetic: 1 } },
      { text: "Look for unconventional approaches", traits: { innovative: 2, adaptable: 1 } },
    ],
  },
  {
    question: "What role does intuition play in your life?",
    answers: [
      { text: "I rely on it heavily", traits: { instinctive: 2, magical: 1 } },
      { text: "I balance it with logic", traits: { analytical: 2, adaptable: 1 } },
      { text: "I use it to understand others", traits: { empathetic: 2, social: 1 } },
      { text: "I trust facts over intuition", traits: { knowledge: 2, perfectionist: 1 } },
    ],
  },
];

const orderTraits = {
  Sphinx: ['knowledge', 'analytical', 'introspective'],
  Werewolf: ['instinctive', 'physical', 'social'],
  Siren: ['empathetic', 'social', 'magical'],
  Dragon: ['courageous', 'physical', 'magical'],
  Pegasus: ['nature', 'social', 'magical'],
  Griffins: ['knowledge', 'innovative', 'adaptable'],
  Vampire: ['social', 'instinctive', 'magical'],
  Centaur: ['nature', 'physical', 'knowledge'],
  "Caucasian Eagle": ['adaptable', 'instinctive', 'physical'],
  Minotaur: ['physical', 'instinctive', 'perfectionist'],
  Cyclops: ['analytical', 'knowledge', 'magical'],
  "Nemean Lion": ['leadership', 'courageous', 'physical'],
  Phoenix: ['magical', 'courageous', 'adaptable'],
  "Teumessian Fox": ['adaptable', 'social', 'instinctive'],
  Basilisk: ['perfectionist', 'magical', 'instinctive'],
};

const orderDescriptions = {
  Sphinx: "Sphinxes are knowledge-seekers who recharge their magic by reading books. They have the extraordinary ability to experience books as if they're really there, making them excellent problem-solvers and sources of wisdom.",
  Werewolf: "Werewolves are tactile and instinctive Fae who can transform into powerful wolf-like creatures. They recharge by running under the moon and operate in pack hierarchies, valuing loyalty and strength.",
  Siren: "Sirens are empathetic Fae who can influence emotions and drain them to recharge their magic. They possess hydrodynamic scales and a magical singing voice that can profoundly affect others.",
  Dragon: "Dragons are powerful Fae who can shift into flying, fire-breathing creatures. They have enhanced senses and recharge by touching gold, embodying strength and majesty.",
  Pegasus: "Pegasi are friendly Fae who can transform into winged, horned horses. They recharge by flying through clouds and are known for their glittery coats and gentle nature.",
  Griffins: "Griffins are knowledge-hungry Fae who recharge by gaining new information. They are curious and intelligent, always seeking to expand their understanding of the world.",
  Vampire: "Vampires are parasitic Fae who need to drink the blood of other Fae to regenerate their power. They possess enhanced strength, speed, and hearing, and often form strong bonds with their chosen 'Sources'.",
  Centaur: "Centaurs are half-human, half-horse Fae who recharge by running. They embody a balance between human intellect and animal instinct, often excelling in physical activities.",
  "Caucasian Eagle": "Caucasian Eagle is a mutatio. In a category of Fae who can fully shift into a creature without any humanoid characteristics. They are highly adaptable and can take on a wide variety of forms.",
  Minotaur: "Minotaurs are strong, bull-headed Fae who excel in navigating complex spaces. They can memorize labyrinthine tunnels with ease and are known for their physical prowess.",
  Cyclops: "Cyclops are Fae with merged eyes who can access and manipulate memories. They are often used in interrogations and have a unique perspective on information and truth.",
  "Nemean Lion": "Nemean Lions are charismatic leaders with enhanced strength and durability. They naturally command respect and are often found in positions of authority.",
  Phoenix: "Phoenixes are incredibly rare and powerful Fae who can control fire and are impervious to most forms of magic. They are known for their resilience and ability to 'rise from the ashes'.",
  "Teumessian Fox": "Teumessian Foxes have the subtle ability to manipulate trust, making them excellent at gathering information and navigation.",
  "Basilisk": "Antidote (can cure all Poisons from biting other Fae), Perfect taste (can recognise any potion ingredients by taste)."}

const FaeOrderQuiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [result, setResult] = useState(null);

  const handleAnswer = (even) => {
	  console.log("handle answer with");
	  console.log(even.target.value);
	 let answer = JSON.parse(even.target.value);
	 console.log(answer);
    setAnswers([...answers, answer]);
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      calculateResult();
    }
  };

  const calculateResult = () => {
    const traits = {};
	console.log(answers);
    answers.forEach(answer => {
		console.log(answer);
      Object.entries(answer.traits).forEach(([trait, value]) => {
        traits[trait] = (traits[trait] || 0) + value;
      });
    });

    let bestMatch = null;
    let highestScore = -1;

    Object.entries(orderTraits).forEach(([order, orderTraitList]) => {
      const score = orderTraitList.reduce((sum, trait) => sum + (traits[trait] || 0), 0);
      if (score > highestScore) {
        highestScore = score;
        bestMatch = order;
      }
    });

    setResult(bestMatch);
  };
	
	const orderExtraDescriptions = (order) => {
		let orderdata = ordersdb.orderTypes.find(item => item.name === order);
		if (orderdata === undefined)
			return "did not find " +order;
		if (orderdata.description === undefined)
			return "not much more is known of the order.";
		return orderdata.description;
	};
		const orderExtraKnown = (order) => {
		let orderdata = ordersdb.orderTypes.find(item => item.name === order);
		if (orderdata === undefined)
			return "did not find " +order;
		if (orderdata.knownCharacters === undefined)
			return "None.";
		return orderdata.knownCharacters.join(',');
	};
	const orderExtraPowers = (order) => {
		let orderdata = ordersdb.orderTypes.find(item => item.name === order);
		if (orderdata === undefined)
			return "did not find " +order;
		if (orderdata.powers === undefined)
			return "None.";
		return orderdata.powers.join(',');
	};
  const resetQuiz = () => {
    setCurrentQuestion(0);
    setAnswers([]);
    setResult(null);
  };

  if (result) {
    return (
      <Card className="w-full max-w-md mx-auto">
        <CardHeader>
          
        </CardHeader>
        <CardContent>
		<Typography  variant="h2" component="div">Your Fae Order</Typography>
          <h2 className="text-2xl font-bold mb-4">{result}</h2>
          <p>{orderDescriptions[result]}</p>
		  <p>{orderExtraDescriptions(result)}</p>
		   <Typography  variant="h4" component="div">Extra known characters with this order</Typography>
		  <p> {orderExtraKnown(result)}</p>
		  <Typography  variant="h4" component="div">Powers</Typography>
		  <p>{orderExtraPowers(result)}</p>
        </CardContent>
        <CardActions>
          <Button onClick={resetQuiz}>Take the Quiz Again</Button>
        </CardActions>
      </Card>
    );
  }

  const question = questions[currentQuestion];


  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        
		
		
      </CardHeader>
      <CardContent>
	  <Typography  variant="h5" component="div">Fae Order Quiz</Typography>
		
       
		
		<Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={(currentQuestion / questions.length) * 100} />
    </Box>
        <h2 className="text-xl font-semibold mb-4">{question.question}</h2>
		<FormControl>
        <RadioGroup onChange={handleAnswer}>
          {question.answers.map((answer, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <FormControlLabel label={answer.text} value={JSON.stringify(answer)} id={`answer-${index}`}  control={<Radio />}  />
               
            </div>
          ))}
        </RadioGroup>
		</FormControl>
      </CardContent>
    </Card>
  );
};

export default FaeOrderQuiz;